<template>
  <div>
    <!--  -->
    <NavigationSubNav main-route="data" :title="actionName" />
    <!--  -->
    <div class="container-fluid pb-1">
      <Loader v-if="showLoader" :description="loadingPrompt" :progress="progress" />

      <div v-else class="mb-sm-4 mx-3 my-4 row" style="top: 75px">
        <div class="col-lg-12">
          <div class="border">
            <div class="row p-4">
              <div class="col">
                <h5 class="h5 text-muted font-weight-bold">{{ title }}</h5>
                <p>{{ message }}</p>
              </div>
              <div>
                <b-dropdown
                  id="dropdown-1"
                  text="Share this Page"
                  class="shadow float-right"
                  variant="primary"
                  :disabled="editting.length == 0"
                  block
                >
                  <b-dropdown-item>Share via Link</b-dropdown-item>
                  <b-dropdown-item>Share to user </b-dropdown-item>
                </b-dropdown>
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 float-right btn-warning shadow"
                >
                  Advance Filters
                </button>
              </div>
            </div>

            <div class="row pt-3 px-3 py-3">
              <div class="col-lg-2 mb-1">
                <span v-if="edittingEnabled">{{ tableDescription }}</span>
              </div>

              <div v-for="entry in filters" :key="entry.name" class="float-right col">
                <el-select
                  v-model="tableFilters[entry.name]"
                  clearable
                  filterable
                  :placeholder="entry.label"
                  class="mr-2 row"
                >
                  <el-option
                    v-for="option in entry.options"
                    :key="option"
                    :value="option"
                    :label="option"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="col-lg-2 mb-1 float-right">
                <el-tooltip
                  :disabled="selectedRows.length <= 0"
                  effect="dark"
                  content="Edit Selected Rows"
                  placement="top"
                >
                  <el-button
                    :disabled="editting.length == 0"
                    type="primary"
                    icon="el-icon-edit"
                    class="action-btns"
                    @click="handleEditSelectedCells"
                  >
                    <small v-if="selectedRows.length > 0" class="tab__badge text-white">{{
                      selectedRows.length
                    }}</small>
                  </el-button>
                </el-tooltip>

                <el-tooltip
                  :disabled="selectedRows.length <= 0"
                  effect="dark"
                  content="Delete selected Rows"
                  placement="top"
                >
                  <el-button
                    :disabled="editting.length == 0"
                    type="danger"
                    class="action-btns"
                    icon="el-icon-delete"
                    @click.prevent="handleDeleteSelectedCells"
                  >
                    <small v-if="selectedRows.length > 0" class="tab__badge text-white">{{
                      selectedRows.length
                    }}</small>
                  </el-button>
                </el-tooltip>
              </div>
            </div>

            <TableComponent
              :show-action="hasConflictingCells"
              :fields="fields"
              bordered
              :data="preview"
              :empty-text="emptyText"
              :loading="tableLoading"
              @sizeChange="handlePageSizeChange"
              @selected="handleSelection"
              @table:cellover="handleCellOver"
              @table:cellout="handleCellOut"
            >
              <template #action="{ row }">
                <el-tooltip
                  v-if="row.item._isValid == false"
                  effect="dark"
                  content="Row contains invalid meta values"
                  placement="top"
                >
                  <b-icon
                    font-scale="1.5"
                    icon="question-circle-fill"
                    variant="danger"
                    aria-label="Help"
                    @click="(event) => showInfo(row)"
                  ></b-icon>
                </el-tooltip>
              </template>
            </TableComponent>

            <div class="row mb-3 p-4 pt-3 px-3 py-3">
              <div class="col-lg-12">
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 action-btn btn-danger shadow"
                  @click.prevent="cancel"
                >
                  Cancel
                </button>
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 action-btn btn-primary shadow"
                  @click.prevent="save"
                >
                  Save
                </button>
                <button
                  :disabled="editting.length == 0 || hasConflictingCells"
                  style="width: fit-content"
                  class="btn mr-2 action-btn float-right btn-success shadow"
                  @click.prevent="proceed"
                >
                  Check Duplicates
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Show successful Normalization prompt -->
    <el-dialog
      :visible.sync="isConfirmationDialogVisible"
      width="28%"
      top="30vh"
      height="60vh"
      center
    >
      <div>
        <div class="row dialog-content">
          <div class="col pb-4">
            <b-icon
              v-if="state === 'NORMALIZATION_ERROR'"
              icon="x-circle"
              font-scale="5"
              scale="1"
              variant="danger"
            ></b-icon>

            <b-iconstack v-else font-scale="5">
              <b-icon stacked icon="circle-fill" variant="success"></b-icon>
              <b-icon stacked icon="check" scale="1" variant="white"></b-icon>
            </b-iconstack>
          </div>
        </div>

        <div class="row dialog-content">
          <div class="col pb-4">
            <span v-if="state === 'NORMALIZATION_ERROR'">Meta-data check failed</span>
            <span v-else> Meta-data exists and normalization is successful</span>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- Edit Invalid records Dialog -->
    <el-dialog title="Edit invalid record entires" :visible.sync="isEditDialogVisible" width="85%">
      <el-table
        height="500"
        :data="edittingCells"
        border
        fit
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column v-for="header in headers" :key="header" :label="header">
          <template slot-scope="{ row }">
            <template v-if="row['_edit']">
              <el-input v-model="row[header]" class="edit-input" size="small" />
            </template>
            <span v-else>{{ row[header] }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Actions" width="120">
          <template slot-scope="{ row }">
            <el-button
              v-if="row._edit"
              type="success"
              size="small"
              icon="el-icon-circle-check-outline"
              @click.prevent="confirmCellEdit(row)"
            >
              Ok
            </el-button>
            <el-button
              v-else
              type="primary"
              size="small"
              icon="el-icon-edit"
              @click.prevent="(event) => toggleEditCell(row)"
            >
              Edit
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isEditDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="confirmCellsEdit">Confirm</el-button>
      </span>
    </el-dialog>

    <!-- shows a prompt , properties are defined in Mixin Object -->
    <ProgressPrompt
      :visible="isProgresPromptVisible"
      :cancel="promptCancel"
      :confirm="promptConfirm"
      :message="promptMessage"
      :title="promptTitle"
      :cancel-text="promptCancelText"
      :cancel-type="promptCancelClass"
      :confirm-text="promptConfirmText"
      :confirm-type="promptConfirmClass"
      @close="onPromptClose"
    />
  </div>
</template>

<script>
/**
 * @typedef {import("../../store/modules/data.old").Cell} Cell
 * @typedef {import("../../store/modules/data.old").NormalizationEntry} NormalizationEntry
 *
 */

import NavigationSubNav from "../../components/NavigationSubNav.vue";
import Loader from "../../components/Loader.vue";
import TableComponent from "../../components/Table.vue";
import DataMixin from "./mixin";
import UploadResource from "../../api/fileResource";
import { mapGetters } from "vuex";
import { random } from "../../utils";

const uploadResource = new UploadResource();

export default {
  name: "MetaDataNormalizer",
  components: {
    NavigationSubNav,
    Loader,
    TableComponent,
  },
  mixins: [DataMixin],
  data() {
    return {
      /**
       * Normalization response parsed by cell index
       */
      validationErrors: {},
      /**
       *
       */
      state: "NO_RECORD",

      /**
       * Holds values currently being modified
       */
      isEditDialogVisible: false,

      /**
       *
       */
      edittingCells: [],
      /**
       *
       */
      isConfirmationDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      normalizationResponse: "data/normalizationResponse",
    }),
    hasConflictingCells() {
      return Object.keys(this.validationErrors).length > 0;
    },
    loadingTitle() {
      return "Checking for meta-data consistency";
    },
    loadingPrompt() {
      if (this.state === "NO_RECORD") return "Please select a file to  Normalize";
      if (this.state === "NORMALIZATION_ERROR") return "Error normalizing record";
      if (this.state === "NORMALIZING_RECORD")
        return "Please wait while the uploaded data is checked for meta-data availability";
      return "";
    },
    title() {
      return "Checking for meta-data";
    },
    message() {
      if ("manual" in this.$route.query) return "Meta-data availability  check required";
      if (!this.hasConflictingCells) return "Your data is good to go";
      return "See conflicting values that exist in your data";
    },
    preview() {
      const data = JSON.parse(JSON.stringify(this.filteredData));
      if (!this.hasConflictingCells) return data;
      const updatedCells = data.map((cell) => {
        if (cell.$id in this.validationErrors) {
          let invalidProperties = this.validationErrors[cell.$id].reduce((previous, current) => {
            previous[current] = "danger";
            return previous;
          }, {});
          cell["_cellVariants"] = invalidProperties;
          cell["_isValid"] = false;
        }
        return cell;
      });
      return updatedCells;
    },
  },
  mounted() {
    this.performNormalization()
      .then(() => {})
      .catch((e) => console.log(e));
  },
  methods: {
    async performNormalization() {
      return new Promise((resolve, reject) => {
        this.state = "NORMALIZING_RECORD";
        this.normalizeData()
          .then(async (response) => {
            if (response.errorMsg === "NETWORK_ERROR") {
              this.state = "NETWORK_ERROR";
              return reject(false);
            } else if (response.errorMsg === "NORMALIZATION_ERROR") {
              this.state = "NORMALIZATION_ERROR";
              await this.parseNormalization(response.failures);
              await this.$store.dispatch("data/cacheNormalizationResponse", response.failures);
              this.isConfirmationDialogVisible = true;
              return reject(false);
            } else {
              await this.$store.dispatch("data/cacheNormalizationResponse", []);
              this.isConfirmationDialogVisible = true;
              return resolve(true);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      });
    },
    /**
     *@param {Array<NormalizationEntry>} failures
      @see NormalizationEntry
     */
    async parseNormalization(failures) {
      this.validationErrors = failures;
    },
    /**
     *
     */
    async normalizeData() {
      try {
        await this.setRequestProgress(random(45, 62));
        this.isApplyingChecks = true;
        this.$progress.start();

        await uploadResource.normalizeData(this.currentRecord.id);
        await this.setRequestProgress(100);

        return { failures: null, errorMsg: null };
      } catch (error) {
        await this.setRequestProgress(random(80, 95));
        const statusCode = error?.response?.status || 500;

        if (500 === statusCode) {
          return { errorMsg: "NETWORK_ERROR", failures: [] };
        }
        if (400 === statusCode) {
          const failures = error?.response?.data?.failures || [];
          const invalidCells = {};
          for (let i = 0; i < failures.length; i++) {
            const element = failures[i];
            if (element[0] in invalidCells) invalidCells[element[0]].push(element[1]);
            else {
              invalidCells[element[0]] = [element[1]];
            }
          }
          return { failures: invalidCells, errorMsg: "NORMALIZATION_ERROR" };
        }
        return { failures: null, errorMsg: "UN_KNOWN" };
      } finally {
        this.$progress.done();
        this.isApplyingChecks = false;
      }
    },
    /**
     *Navigates to  next index on the  Next index Navigation Bar
     @see {NavigationSubNav}
     */
    async proceed() {
      if (this.hasConflictingCells || this.normalizationResponse == null) {
        this.tableLoading = true;
        this.$progress.start();
        return this.performNormalization()
          .then(() => {
            this.onNormalizationPassed();
          })
          .catch((e) => console.log(e))
          .finally(() => {
            this.tableLoading = false;
            this.$progress.done();
          });
      }
      return this.onNormalizationPassed();
    },
    /**
     *
     */
    onNormalizationPassed() {
      setTimeout(async () => {
        this.$store.commit("data/SET_NORMALIZATION_STATE", true);
        this.isConfirmationDialogVisible = false;
        this.$router
          .push("/data/duplicates")
          .then(() => {})
          .catch((e) => console.log(e));
      }, 1000);
    },

    /**
     * @typedef {import("../../components/Table.vue").CellOverEvent} CellOverEvent
     * @see CellOverEvent
     */

    // eslint-disable-next-line no-unused-vars
    handleCellOver({ id, property, target }) {},

    /**
     *
     */
    handleCellOut() {},

    /**
     *
     */
    handleEditSelectedCells() {
      this.edittingCells = JSON.parse(JSON.stringify(this.selectedRows));
      this.$nextTick(() => {
        this.isEditDialogVisible = true;
      });
    },

    /**
     *
     */
    save() {
      this.showProgressPrompt(
        "Save progress",
        "Are you sure you want to save and continue later?",
        {
          cancel: "Continue",
          confirm: "Save and continue later",
          confirmClass: "primary",
          async confirmCallback() {
            this.$store.dispatch("data/storeData");
          },
        }
      );
    },

    /**
     *
     */
    showInfo(row) {
      console.log(row);
    },

    /**
     *
     */
    confirmCellEdit(row) {
      this.$set(row, "_edit", false);
    },

    /**
     *
     */
    toggleEditCell(row) {
      const state = !row._edit;
      this.$set(row, "_edit", state);
    },

    /**
     *
     */
    confirmCellsEdit() {
      this.isEditDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.action-btns {
  width: 85px;
}
.dialog-content {
  text-align: center;
}
</style>
